import {
  deserializeFiltersFromPath,
  serializeFiltersToPath,
} from '@finn/auto-ui/modules/products/filters';
import { BASE_SUBSCRIPTION_PATH } from '@finn/ua-constants';
import { getFilters } from '@finn/ua-vehicle';
import { USPBannerData } from '@finn/ui-cosmic';
import {
  config,
  FakeDefaultLocale,
  getLocaleFromContext,
} from '@finn/ui-utils';
import * as Sentry from '@sentry/node';
import { GetStaticPaths, GetStaticProps, NextPage } from 'next';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';

import ErrorPage from '~/components/ErrorPage';
import { GetProductListItemsResponse } from '~/modules/products/list';
import { BaseLandingPage, SeoHeaderData } from '~/types/cosmicModules';
import { AvailableFilters } from '~/types/filter';
import { IErrorPage } from '~/types/general';
import { IPropsProductListingPage } from '~/types/productListing';
import PageBodyBuilder from '~/ui-modules/PageBodyBuilder';
import { StickyNavContent } from '~/ui-modules/StickyNav/types';
import {
  getLandingPageGetServerSideProps,
  migrateToEnRoutesRedirects,
  shouldConsiderAsLandingPage,
} from '~/utils/landingPage';
import {
  addSentryRenderPageBreadcrumb,
  addSentryScopeTag,
} from '~/utils/sentry';

interface IStickyNavContentProp {
  stickyNavContent: StickyNavContent;
}

interface ICommonProps extends BaseLandingPage {
  productListItemsResponse?: GetProductListItemsResponse;
  nextAvailableFilters: AvailableFilters;
  seoHeader: SeoHeaderData;
  renderMetaContent?: (
    pageData: BaseLandingPage['pageData'],
    { uspBanner }: { uspBanner: USPBannerData }
  ) => ReactNode;
}

export type Props = ICommonProps &
  IErrorPage &
  IPropsProductListingPage &
  IStickyNavContentProp;

const SeoRelevantPLP: NextPage<Props> = ({
  pageData,
  statusCode,
  errorMessage,
  reviews,
  brands,
  stickyNavContent,
}) => {
  const router = useRouter();

  addSentryRenderPageBreadcrumb('/pages/[...slug]', router.asPath);

  if (statusCode) {
    addSentryScopeTag('slug', router.asPath);

    return (
      <ErrorPage
        pageData={pageData}
        statusCode={statusCode}
        error={new Error(errorMessage)}
      />
    );
  }

  return (
    <PageBodyBuilder
      data={pageData}
      reviews={reviews}
      brands={brands}
      stickyNavContent={stickyNavContent}
    />
  );
};

export const getStaticProps: GetStaticProps = async (ctx) => {
  try {
    addSentryRenderPageBreadcrumb('/pages/[...slug]', 'Catch All Index - gSSP');

    const pathParams = ctx.params.slug as string[];
    const slugArray = Array.isArray(ctx?.params?.slug)
      ? ctx?.params?.slug
      : [ctx?.params?.slug];

    const locale = getLocaleFromContext(ctx);
    const localePrefix = locale !== FakeDefaultLocale ? `${locale}/` : '';

    console.log('RENDERING PAGE FOR PATH', pathParams);
    if (
      (await shouldConsiderAsLandingPage(pathParams, locale)) &&
      !slugArray.includes(BASE_SUBSCRIPTION_PATH)
    ) {
      const isNested = pathParams.length > 1;

      const slug = isNested ? pathParams.join('-') : pathParams[0];

      const props = await getLandingPageGetServerSideProps(
        migrateToEnRoutesRedirects[slug] || slug,
        locale
      );

      if (props === null) {
        return { notFound: true };
      }

      return {
        props,
        revalidate: config.REVALIDATION_LONG_TIMEOUT,
      };
    }

    const filtersResponse = await getFilters({
      filters: {},
      locale,
    });

    const genericUrlFilterValues = deserializeFiltersFromPath(
      `/${pathParams.join('/')}`,
      '/',
      locale,
      filtersResponse
    );

    if (Object.keys(genericUrlFilterValues).length > 0) {
      return {
        redirect: {
          permanent: true,
          destination: serializeFiltersToPath(
            genericUrlFilterValues,
            `${localePrefix}${BASE_SUBSCRIPTION_PATH}/`,
            locale,
            filtersResponse
          ),
        },
      };
    }

    return {
      notFound: true,
    };
  } catch (e) {
    console.error(e);
    Sentry.captureException(e);

    return {
      notFound: true,
      revalidate: config.REVALIDATION_LONG_TIMEOUT,
    };
  }
};

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: [],
    fallback: 'blocking',
  };
};
export default SeoRelevantPLP;
